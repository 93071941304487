import '@fontsource/inter/300.css';
import '@fontsource/inter/400.css';
import '@fontsource/inter/500.css';
import '@/shared/styles/tailwind.css';
import '@/shared/styles/globals.css';
import '@/shared/styles/nprogress.css';
import '@/shared/styles/fonts.css';

import * as React from 'react';
import * as Portal from '@radix-ui/react-portal';

import { withProviders } from '@/apps/providers';

import { NetworkToast } from '@/features/network-toast';

import { DisplayOnBrowserMount } from '@/shared/components/display-on-browser-mount';
import { NextProgress } from '@/shared/components/nprogress';
import { Toaster } from '@/shared/components/ui/sonner';
import { PageSeo } from '@/shared/lib/seo';
import { EnhancedAppProps } from '@/shared/types/enhanced-app-props';

function App(props: EnhancedAppProps) {
  const { Component, pageProps, err } = props;

  const Layout = Component.Layout ?? React.Fragment;

  return (
    <>
      <PageSeo />

      <NextProgress />

      <Layout>
        <Component {...pageProps} err={err} />
      </Layout>

      <NetworkToast />

      <DisplayOnBrowserMount>
        <Portal.Root>
          <Toaster />
        </Portal.Root>
      </DisplayOnBrowserMount>
    </>
  );
}

export default withProviders(App);
