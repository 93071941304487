import { useUnit } from 'effector-react';
import { RiWifiOffLine } from 'react-icons/ri';

import { $online } from '@/shared/lib/network';

export const NetworkToast = () => {
  const [online] = useUnit([$online]);

  if (online) return null;

  return (
    <div className="sticky bottom-0 left-2 right-2 flex items-center space-x-4 rounded bg-muted p-2 shadow-sm">
      <div className="flex h-9 w-9 items-center justify-center rounded-full bg-neutral-600">
        <RiWifiOffLine />
      </div>

      <div className="flex flex-col">
        <span className="text-md">You&apos;re offline now</span>
        <span className="text-sm text-muted-foreground">Oops! Internet is disconnected</span>
      </div>
    </div>
  );
};
